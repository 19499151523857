.CardCell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09) !important;
  &:last-child {
    border: 0;
  }
  &:hover {
    background-color: #e6f7ff;
  }
}

.close {
  color: #8db2bf;
}

.close:hover {
  color: var(--primary-light);
}

.ant-spin-dot-item {
  background-color: var(--primary-main);
}