.ant-tabs-tab {
    background-color: white !important;
}

.ant-tabs-tab:hover {
    color: #007A84;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #007A84;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #007A84;
}