.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #007A84;
}

.logo {
  height: 74px;
  padding: 10px;
}
.ant-menu {
  .ant-menu-item,
  .ant-menu-submenu {
    a,
    div {
      color: #8db2bf;

      &:hover {
        color: #007A84;
      }
    }
  }
  .ant-menu-item-selected {
    color: #007A84;
  }

  .ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    color: #007A84;
  }
}
