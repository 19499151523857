@mixin centerAligned {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authentication-message {
  @include centerAligned;
  height: 100%;

  &__headline {
    font-size: 18px;
  }

  &__description {
    margin: 10px 0;
    color: #929aa8;
    font-size: 11px;
    display: block;
  }

  &__children {
    @include centerAligned;
    width: 100%;
  }

  &__flex-container {
    @include centerAligned;
  }

  &__container {
    @include centerAligned;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.1);
    padding: 40px 37px;
    min-width: 355px;
    max-width: 90%;
    text-align: center;
    color: #405e6c;
  }
}
