html {
  --antd-wave-shadow-color: #045B62 !important;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #045B62;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: #f5f7f7;
}

.CardCell:hover {
  background: #f5f7f7;
}

.ant-dropdown-menu-item a:hover,
.ant-dropdown-menu-item a:active {
  background: #f5f7f7;
}

.ant-menu-item:hover {
  background: #f5f7f7;
}

//
// Custom Ant card styles
//
.ant-card {
  // There is no border and shadow prop in Antd variables to override
  border: solid 1px #e6eef0;
  border-radius: 5px;
  box-shadow: 0 4px 9px 0 rgba(55, 92, 130, 0.11); // Custom padding and text style in cad header titles

  .ant-card-head-title {
    padding: 14px;
    font-size: 16px;
    font-weight: bold;
    color: #4e505e;
    align-self: center;
  }

  // Fixing position of extra element in cards, this is need because we have overridden "@card-padding-base"
  .ant-card-extra {
    padding: 14px;
  }
}

//
// Custom Ant Table styles
//
// Positions the table pagination component below the card in tables
.ant-table-pagination {
  position: absolute;
  right: 0px;
}

.ant-pagination-item-active {
  color: #5575CE !important;
  border-color: #5575CE;
}

.ant-pagination-item-active a {
  color: #5575CE !important;
  border-color: #5575CE;
}

.ant-pagination-item a:hover {
  color: #5575CE !important;
  border-color: #5575CE;
}

.ant-pagination-item:hover {
  color: #5575CE !important;
  border-color: #5575CE;
}

.ant-pagination-prev-active {
  color: #5575CE;
  border-color: #5575CE;
}

.ant-input-group .ant-input:focus {
  box-shadow: #5575CE 0px 0px 6px 0px;
  border-color: #5575CE;
  color: #5575CE;
}

.ant-input-group .ant-input:hover {
  border-color: #5575CE;
  color: #5575CE;
}

.ant-input-affic-wrapper:hover {
  border-color: #5575CE;
  color: #5575CE;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: #5575CE 0px 0px 6px 0px;
  border-color: #5575CE;
  color: #5575CE;
}


.ant-btn:active,
.ant-btn:hover,
.ant-btn:focus {
  border-color: #5575CE;
  color: #5575CE;
}

.ant-input-search .ant-input:focus {
  box-shadow: #5575CE 0px 0px 2px 0px;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
  border-top-color: #d9d9d9;
  border-right-color: #d9d9d9;
  border-bottom-color: #d9d9d9;
  border-left-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  color: black;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #5575CE;
  border-color: #5575CE;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #5575CE;
}

.ant-table {

  // Adding border to table body
  .ant-table-body {
    border-top: solid 1px #e8e8e8;
  }

  // Sets the table header font-size and reduces the padding
  .ant-table-thead {
    &>tr {
      &>th {
        padding-top: 10px;
        padding-bottom: 10px;

        &>span {
          font-size: 11px;
        }
      }
    }
  }

  // Restyles the action buttons within the table
  .action-buttons {
    width: 24px;
    height: 24px;
    border: none;
    box-shadow: 0 5px 8px 0 rgba(51, 131, 131, 0.21), 0 1px 2px 0 rgba(62, 74, 85, 0.22);
    margin-right: 8px;
  }
}

//
// Custom Ant Input styles
//

// Styles the position of the external label when content is entered
.ant-dropdown-menu-item {
  padding: 0px;

  .ant-dropdown-menu-title-content {
    div {
      padding: 5px 12px;
    }

    a {
      padding: 5px 12px;
      display: inline-block;
    }
  }
}

// Removes the margin below input fields to group the closer
.ant-form-item-with-help {
  margin-bottom: 0px !important;
}

// Ensures that the Select input fills out the col width
.ant-form-item-control-wrapper {
  width: 100%;
}

// Custom primary button styling
button.ant-btn.ant-btn-primary {
  border-color: var(--primary-main);
  background-color: var(--primary-main);
  color: white;
  box-shadow: var(--box-shadow-xs);
  border-radius: 6px;
}

button.ant-btn.ant-btn-primary:hover {
  border-color: var(--primary-light);
  background-color: var(--primary-light);
}

button.ant-btn.ant-btn-primary:active {
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
}

// Custom secondary button styling
button.ant-btn.ant-btn-secondary {
  font-family: var(--font-family);
  background-color: white;
  border-color: none;
  color: var(--secondary-contrast-text);
  box-shadow: var(--box-shadow-xs);
  border-radius: 6px;
}

button.ant-btn.ant-btn-secondary:hover {
  border-color: var(--primary-light);
  color: var(--primary-light);
}

button.ant-btn.ant-btn-secondary:focus {
  border-color: var(--primary-dark);
  color: var(--primary-dark);
}

button.ant-btn.ant-btn-secondary:active {
  border-color: var(--primary-dark);
  color: var(--primary-dark);
}

// Custom default button styling
button.ant-btn.ant-btn-default {
  background-color: white;
  border-color: none;
  color: var(--secondary-contrast-text);
  box-shadow: var(--box-shadow-xs);
  border-radius: 6px;
}

button.ant-btn.ant-btn-default:hover {
  border-color: var(--primary-light);
  color: var(--primary-light);
}

button.ant-btn.ant-btn-default:active {
  border-color: var(--primary-dark);
  color: var(--primary-dark);
}

.ant-btn.ant-input-search-button.ant-btn.primary {
  box-shadow: none;
}

li.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

li.py-2.text-common-inverse {
  background-color: #ffffff;
  color: var(--text-color-primary, #4f5c73);
  font-weight: 800 !important;

  &:focus,
  &:hover {
    background-color: var(--d-dropdown-menu-item-hover-background-color, #f3f4f7);
    border-color: var(--primary-light);
  }
}

li.py-2.text-common-inverse[aria-selected="true"] {
  background-color: var(--d-dropdown-menu-item-hover-background-color, #f3f4f7);
}

li.py-2.text-common-default,
li.py-2.text-common-low {
  color: #4f5c73;
  font-weight: 600;

  &:focus,
  &:hover {
    background-color: var(--d-dropdown-menu-item-hover-background-color, #f3f4f7);
  }

}

li.py-2.bg-secondary-default {
  background-color: var(--d-dropdown-menu-item-hover-background-color, #f3f4f7);
}

li.py-2.bg-common-higher,
li.py-2.bg-common-high {
  color: var(--text-color-primary);

  &:focus,
  &:hover {
    border-color: var(--primary-light);
    background-color: var(--d-dropdown-menu-item-hover-background-color, #f3f4f7);
  }

}

div.absolute.bottom-0.right-2 {
  right: 0.5rem;
}

div svg.h-4.w-4 {
  width: 1rem;
  height: 1rem;
}
