.space-filler {
  padding: 32px;
  text-align: center;
}

.space-filler-description {
  font-size: 14px;
  font-weight: 600;
  color: #8db2bf;
  text-align: center;
  margin-top: 11px;
}
